import { SharedModule } from "@modules/shared/shared.module";

import { GlobalToastModule } from "@core/components/global-toast/global-toast.module";
import { AplicationHttpClientModule } from "@core/config/application-http-client/application-http-client.module";
import { TranslationModule } from "@core/config/translation/translation.module";
import { AutorizationInterceptor } from "@core/interceptors/authorization.intercetor";
import { TenantInterceptor } from "@core/interceptors/tenant.interceptor";
import { AuthRequest } from "@core/requests/auth.request";
import { AuthenticationService } from "@core/services/authentication.service";
import { AuthorizationService } from "@core/services/authorization.service";
import { CacheService } from "@core/services/cache.service";
import { TenantService } from "@core/services/tenant.service";
import { MedicProfileState } from "@core/state/medic-profile.state";

import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule, Provider } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

const customProviders: Provider[] = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AutorizationInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: TenantInterceptor,
    multi: true,
  },
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    SharedModule,
    BrowserAnimationsModule,
    GlobalToastModule,
    TranslationModule,
  ],
  exports: [],
  providers: [
    ...customProviders,
    AplicationHttpClientModule,
    AuthRequest,
    AuthenticationService,
    AuthorizationService,
    TenantService,
    MedicProfileState,
    CacheService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
